// Generated by Framer (68f6254)

import { addFonts, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["soat5Y7ec"];

const serializationHash = "framer-MiaUM"

const variantClassNames = {soat5Y7ec: "framer-v-621nrk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "soat5Y7ec", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://wa.me/message/6R4GUGK6HICCG1"}><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", pixelHeight: 500, pixelWidth: 499, src: "https://framerusercontent.com/images/BCRbWHxuV3UrYZDQhaEwwfZkN7I.png"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-621nrk", className, classNames)} framer-1n9y7le`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"soat5Y7ec"} ref={ref ?? ref1} style={{...style}}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MiaUM.framer-1n9y7le, .framer-MiaUM .framer-1n9y7le { display: block; }", ".framer-MiaUM.framer-621nrk { height: 65px; position: relative; text-decoration: none; width: 65px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 65
 * @framerIntrinsicWidth 65
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNhi_v4yiV: React.ComponentType<Props> = withCSS(Component, css, "framer-MiaUM") as typeof Component;
export default FramerNhi_v4yiV;

FramerNhi_v4yiV.displayName = "Whatsapp";

FramerNhi_v4yiV.defaultProps = {height: 65, width: 65};

addFonts(FramerNhi_v4yiV, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})